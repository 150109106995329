<template>
  <div class="_events">
    <section class="_section__hero-wrapper">
      <section class="_section__events--intro">
        <div class="_media">
          <picture class="_picture--1">
            <source
              media="(min-width: 1200px)"
              srcset="@/assets/images/events_intro_1-1200.jpg"
            />
            <source
              media="(min-width: 600px)"
              srcset="@/assets/images/events_intro_1-Small.jpg"
            />
            <img
              class="_image"
              src="@/assets/images/events_intro_1-Small.jpg"
              alt="events"
            /> </picture
          ><picture class="_picture--2">
            <source
              media="(min-width: 1200px)"
              srcset="@/assets/images/events_intro_2-1200.jpg"
            />
            <source
              media="(min-width: 600px)"
              srcset="@/assets/images/events_intro_2-Small.jpg"
            />
            <img
              class="_image"
              src="@/assets/images/events_intro_2-Small.jpg"
              alt="events"
            />
          </picture>
        </div>
        <div class="_card--raised">
          <h2 class="_title">Introduction</h2>
          <div class="_paragraphs">
            <p class="_text">
              A good event venue makes all the difference. If you’re looking for
              a perfect destination for an intimate wedding, the picturesque
              backdrop of Pej river and the panoramic view of hills would
              capture the most memorable picture of your wedding day for you and
              your family.
            </p>
            <p class="_text">
              The space can also transform itself into a fun field trip,
              corporate retreat, camp, gig, workshop, all under one roof. We are
              fully equipped to meet your requirements with home cooked meals
              and comfortable accommodation. Kamal farms is a space that can be
              adapted to your specific needs for an ideal event.
            </p>
          </div>
        </div>
      </section>
    </section>

    <section class="_section__custom--overlap">
      <div class="_media">
        <picture class="_picture">
          <source
            media="(min-width: 1200px)"
            srcset="@/assets/images/events_wedding-1200.jpg"
          />
          <source
            media="(min-width: 600px)"
            srcset="@/assets/images/events_wedding-Small.jpg"
          />
          <img
            class="_image"
            src="@/assets/images/events_wedding-Small.jpg"
            alt="about"
          />
        </picture>
      </div>
      <div class="_card--raised">
        <h2 class="_title">Engagement & Wedding</h2>
        <div class="_paragraphs">
          <p class="_text">
            Weddings are an intimate and personal event. They mark the beginning
            of a new chapter in your life. Wouldn’t it be ideal to celebrate
            this occasion with your near and dear ones? Make the endless sky,
            the mighty trees and the blissful Pej river a spectator of your
            oaths and vows.
          </p>
          <p class="_text">
            The villa and cottage can accommodate upto 50 guests and lawns
            spanning over 10,000 sq.ft. can host up to another 150 day guests.
            Have a memorable wedding filled with love and laughter at Kamal
            Farms.
          </p>
        </div>
        <!-- <button class="btn">Check Availability</button> -->
      </div>
    </section>

    <section class="_section__custom--overlap">
      <div class="_media">
        <picture class="_picture">
          <source
            media="(min-width: 1200px)"
            srcset="@/assets/images/events_corporate-1200.jpg"
          />
          <source
            media="(min-width: 600px)"
            srcset="@/assets/images/events_corporate-Small.jpg"
          />
          <img
            loading="lazy"
            class="_image"
            src="@/assets/images/events_corporate-Small.jpg"
            alt="about"
          />
        </picture>
      </div>
      <div class="_card--raised">
        <h2 class="_title">Corporate Events</h2>
        <div class="_paragraphs">
          <p class="_text">
            Working at an office and being confined to a desk job can be
            exhausting for most of us. We all need a break once in a while. Step
            out of your office with your colleagues and crew for a corporate
            offsite.
          </p>
          <p class="_text">
            Imagine conducting an office conference underneath trees, a
            hackathon by a river, or a team bonding session amidst hills. Kamal
            Farms is an ideal venue to blow off steam with your crew and
            colleagues for an office outing.
          </p>
        </div>
        <!-- <button class="btn">Check Availability</button> -->
      </div>
    </section>

    <section class="_section__custom--overlap">
      <div class="_media">
        <picture class="_picture">
          <source
            media="(min-width: 1200px)"
            srcset="@/assets/images/events_workshops-1200.jpg"
          />
          <source
            media="(min-width: 600px)"
            srcset="@/assets/images/events_workshops-Small.jpg"
          />
          <img
            loading="lazy"
            class="_image"
            src="@/assets/images/events_workshops-Small.jpg"
            alt="about"
          />
        </picture>
      </div>
      <div class="_card--raised">
        <h2 class="_title">Workshops</h2>
        <div class="_paragraphs">
          <p class="_text">
            We enjoy having people of all ages at Kamal Farms for an educational
            experience. If you are a teacher looking for a perfect space or a
            student hoping to move your class out of your usual classroom, we
            have an ideal location for adults and children to enjoy a learning
            experience.
          </p>
          <p class="_text">
            The farms are absolutely charming for nature specific workshops such
            as Butterfly gardening, the art of bonsai, beekeeping, permaculture
            design, plant craft- horticulture techniques, pottery, yoga,
            meditation, soul healing and much more.
          </p>
        </div>
        <!-- <button class="btn">Check Availability</button> -->
      </div>
    </section>

    <section class="_section__events--contact-us">
      <div class="_card__custom" id="contact-form">
        <h2 class="_title--light">Planning an Event, we've got you covered!</h2>
        <router-link
          class="_btn_link"
          :to="{ name: 'About', hash: '#contact-form' }"
          >Contact Us</router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Events",
  metaInfo: {
    title: "Events",
    tags: ["Engagement", "Wedding", "Corporate Events", "Workshops", "Events"],
  },
};
</script>

<style scoped lang="scss">
._events {
  ._section__events--intro {
    @extend ._section__custom--overlap;
    ._title {
      padding: 0 0 $paddingMinimum;
    }
    ._media {
      display: grid;
      grid-template-rows: 1fr 6fr 5fr;
      grid-template-columns: 0.5fr 1.5fr 1fr 3fr;
      ._picture {
        &--1 {
          @extend ._picture;
          grid-area: 2/2/3/4;
          z-index: 1;
        }
        &--2 {
          @extend ._picture;
          grid-area: 1/3/-1/-1;
        }
      }
    }
  }
  ._section__events--contact-us {
    @extend ._section__custom;
    ._card__custom {
      // @extend ._card__auto;
      @extend ._card--raised;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      background-color: $ochre;
      ._title--light {
        padding: 0;
      }
      ._btn_link {
        @extend ._subtitle;
        padding: 15px 30px;
        // margin: 0;
        align-self: flex-start;
        color: $ochre;
        background-color: $white;
        &:hover {
          border: 1px solid $white;
          background-color: darken($ochre, 10%);
          color: $white;
        }
      }
    }
  }
}
@media screen and (max-width: 400px) {
  ._events {
    ._section__events--intro {
      ._media {
        ._picture {
          &--1 {
            grid-area: 2/1/3/4;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 600px) {
  ._events {
    ._section__events--contact-us {
      ._card__custom {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
}
</style>